import * as React from "react"
import PortfolioWrapper from "./style"
import Posts from "./Posts"

type PersonalBlogProps = {}

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = ({
  ...props
}) => {
  return (
    <PortfolioWrapper {...props}>
      <Posts />
    </PortfolioWrapper>
  )
}

export default PersonalBlog
